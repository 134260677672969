import React from 'react';
import metalCraft from '../../images/cnc.jpg';

const ServiceHeader = () => {
    return (
        <div className="service-background py-5">
            <div className="container py-4">
                <div className="row py-5 justify-content-between">
                    <div className="col-md-6">
                        <img className="metalcraft" src={metalCraft} alt="Metal Craft"/>
                    </div>
                    <div className="col-md-5">
                        <h5 className="text-white">Our Services</h5>
                        <h2 className="text-white py-3">Tailored Metal Design Solutions for Your Business.</h2>
                        <p className="text-white pb-3">We are here to provide you with advanced and customized metalworking services. With our passion for craftsmanship and our team's expertise, we assist you in optimizing your metal design projects. From concept to creation, we stand by your side to ensure the success of your metalwork in the digital world.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceHeader;