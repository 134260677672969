import React from 'react';
import logo from '../../images/logo1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    const facebookUrl = 'https://m.facebook.com/profile.php?id=100091051845513';
    const tiktokUrl = 'https://www.tiktok.com/@artcnc.mk';
    const instagramUrl = 'https://www.instagram.com/artcnc.mk/';

    return (
        <div className="footer">
            <div className="container">
                <div className="row py-3">
                    <div className="col-md-3 socials-logo">
                        <a href="/"><img className="img-fluid" src={logo} alt="ART CNC" /></a>
                    </div>
                    <div className="col-md-5 socials-copyright">
                        <p className="text-black">Copyright © 2023 ArtCNC. All rights reserved.</p>
                    </div>
                    <div className="col-md-4 socials" >
                        <a href={facebookUrl} target="_blank" rel="noopener noreferrer" style={{padding: '5px'}}>
                            <FontAwesomeIcon icon={faFacebook} className="text-black" size="2x" />
                        </a>
                        <a href={tiktokUrl} target="_blank" rel="noopener noreferrer"  style={{padding: '5px'}}>
                            <FontAwesomeIcon icon={faTiktok} className="text-black" size="2x" />
                        </a>
                        <a href={instagramUrl} target="_blank" rel="noopener noreferrer"  style={{padding: '5px'}}>
                            <FontAwesomeIcon icon={faInstagram} className="text-black" size="2x" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
