import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <div className="head-container">
            <div className="header-text">
                <h4 className="subheading">YOUR CNC DESIGN SPECIALISTS</h4>
                <h1 className="header-text-style">Transforming Ideas into Metal Masterpieces</h1>
                <p className="fs-4 ">We are passionate about CNC technology and crafting metal desks with unique designs. <br/>Our commitment to innovation and quality is unparalleled. </p>
                {/* <button className="button-white"><span className="">Get Started</span></button> */}
            </div>
        </div>
    );
};

export default Header;