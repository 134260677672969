import React from 'react';
import './Statistic.css';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Statistic = () => {
    return (
        <div className="statistic-container d-flex align-items-center">
            <div className="container py-5">
                <div className="row justify-content-center align-items-center my-5">
                    <div className="col-md-7">
                        <h5 className="text-white">Our Mission</h5>
                        <h1 className="text-white brand-font statistic-title">Passionate About CNC Technology</h1>
<p className="text-white">We are dedicated to crafting metal desks with unique designs using cutting-edge CNC technology. Our commitment to innovation and quality is unparalleled. Proudly headquartered in North Macedonia, we are on a mission to provide exceptional CNC design solutions. Join us in shaping the future of metal design.</p>
              <div className="row text-center justify-content-center">
                            <div className="col-md m-3 col-6">
                                <CircularProgressbar strokeWidth={4} value={100} maxValue={100} text={`1382`} />
                                <h5 className="text-white">Total Projects</h5>
                            </div>
                            <div className="col-md m-3 col-6">
                                <CircularProgressbar strokeWidth={4} value={100} maxValue={100} text={`942`} />
                                <h5 className="text-white">Metal Work</h5>
                            </div>
                            <div className="col-md m-3 col-6">
                                <CircularProgressbar strokeWidth={4} value={100} maxValue={100} text={`440`} />
                                <h5 className="text-white">Revenue Growth</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Statistic;
