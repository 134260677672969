import React from 'react';
import './Overview.css';
import ProgressBar from "@ramonak/react-progress-bar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChair, faHeart, faHistory, faLayerGroup } from '@fortawesome/free-solid-svg-icons';

const AboutUs = () => {
    return (
        <div className="bg-light py-5">
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-6 pe-2 pb-3">
                        <h6>Metalwork </h6>
                        <ProgressBar completed={100} bgColor="#000000" height="15px" />
                        <h6 className="mt-3">CNC Design</h6>
                        <ProgressBar completed={90} bgColor="#000000" height="15px" />
                        <h6 className="mt-3">Innovation</h6>
                        <ProgressBar completed={70} bgColor="#000000" height="15px" />
                    </div>
                    <div className="col-md-6">
                        <h2>About Us</h2>
                        <p> Our fervor lies in transforming concepts into tangible metal designs through innovation and uncompromising quality. Proudly rooted in North Macedonia, we are committed to delivering outstanding CNC design solutions. Join us in shaping the future of metal craftsmanship.</p>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-3 text-center">
                        <FontAwesomeIcon icon={faHistory} size="2x" />
                        <h1 className='overview-text'>15</h1>
                        <h4>Years</h4>
                    </div>
                    <div className="col-md-3 text-center">
                        <FontAwesomeIcon icon={faChair} size="2x" />
                        <h1 className='overview-text'>1037</h1>
                        <h4>Designs</h4>
                    </div>
                    <div className="col-md-3 text-center">
                        <FontAwesomeIcon icon={faHeart} size="2x" />
                        <h1 className='overview-text'>879</h1>
                        <h4>Clients</h4>
                    </div>
                    <div className="col-md-3 text-center">
                        <FontAwesomeIcon icon={faLayerGroup} size="2x" />
                        <h1 className='overview-text'>1382</h1>
                        <h4>Projects</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
