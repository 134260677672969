import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faRandom, faTools } from '@fortawesome/free-solid-svg-icons';

const ChooseUs = () => {
    return (
        <div className="container text-center py-5 my-5" id="careers">
            <div className="py-2 mb-5">
                <h5 className="text">WHY CHOOSE US</h5>
                <h1 className="brand-font">Give Your Business a Boost</h1>
                <p>We drive your business forward with passion and expertise.</p>
            </div>

            <div className="row">
                <div className="col-md px-3 py-2">
                    <FontAwesomeIcon className="text m-1" icon={faHandshake} size="3x" />
                    <h4 className="brand-font pt-2">We Are Reliable</h4>
                    <p>We are always by your side, ready to help you grow and face challenges.</p>
                </div>
                <div className="col-md px-3 py-2">
                    <FontAwesomeIcon className="text m-1" icon={faTools} size="3x" />
                    <h4 className="brand-font pt-2">We Are Competent</h4>
                    <p>Our expertise is your advantage. We support you with cutting-edge solutions.</p>
                </div>
                <div className="col-md px-3 py-2">
                    <FontAwesomeIcon className="text m-1" icon={faRandom} size="3x" />
                    <h4 className="brand-font pt-2">We Are Flexible</h4>
                    <p>We adapt to your needs to provide tailor-made solutions.</p>
                </div>
            </div>
        </div>
    );
};

export default ChooseUs;
