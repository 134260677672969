import React from 'react';
import './AboutUs.css';
import aboutPhoto from '../../images/about-photo.png';


const AboutUs = () => {
    return (
        <div className="container pb-5" id="aboutus">
             <div className="row">
                <div className="col-md-6 pe-5 pt-5 mt-5 pb-5">
                    <h6 className="text fs-4">About Us</h6>
                    <h1 className="about-heading-text pt-2">Welcome to ART CNC MK</h1>
                    <p className="text-dark py-3">
                        At ART CNC MK, we specialize in custom metalwork through CNC technology. Our passion is to turn ideas into metal design realities through innovation and top-notch quality. Proudly based in North Macedonia, we are dedicated to delivering exceptional CNC design solutions. Join us in shaping the future of metal design.
                    </p>
                </div>


                <div className="col-md-6 text-end">
                    <img className="img-fluid about-image" src={aboutPhoto} alt="About US"/>
                </div>
            </div>
            
        </div>
    );
};

export default AboutUs;